import React from 'react';

import { sendClick } from 'reaxl-analytics';
import { footerLinkClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import { StaticFooter } from 'reaxl-footer';

function Footer() {
    const {
        col_enable_one_trust_footer: [enableOneTrustFooter],
    } = useFeatures([
        'col_enable_one_trust_footer',
    ]);

    const handleFooterClick = (event, data = {}) => {
        sendClick(footerLinkClick, event, data);
    };

    return (
        <StaticFooter
            onClick={handleFooterClick}
            enableOneTrustFooter={enableOneTrustFooter}
        />
    );
}

function AtcFooter() {
    return (
        <Footer />
    );
}

export default AtcFooter;
